import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><a parentName="p" {...{
        "href": "https://trailguide.net"
      }}>{`Trailguide`}</a>{` is a
`}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://en.wikipedia.org/wiki/Progressive_web_application"
        }}>{`web-app`}</a></strong>{` to find
and share the best `}<b>{`outdoor activities`}</b>{` and destinations, mainly for
singletrack mountain biking at the moment, but this is being extended.`}</p>
    <br />
    <p><em parentName="p">{`Find activities`}</em>{` nearby or plan your next bigger trip.`}</p>
    <br />
    <p><em parentName="p">{`Navigate`}</em>{` with the app or download the GPX tracks.`}</p>
    <br />
    <p>{`Check out `}<em parentName="p">{`reviews`}</em>{` and `}<em parentName="p">{`condition`}</em>{` reports to get the best experience.`}</p>
    <br />
    <p>{`Be part of the `}<em parentName="p">{`community`}</em>{` and upload tracks and give feedback.`}</p>
    <Image src="/features/mapandlist2.jpg" className="w-2/3 mx-auto my-12" mdxType="Image" />
    <Link className="float-right" to="/features/oneminute" mdxType="Link">
  The app in one minute →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      